
import { eoRendererMixin, metaDataMixin, dateFormatMixin } from '~/mixins'

export default {
  mixins: [
    dateFormatMixin,
    eoRendererMixin,
    metaDataMixin,
  ],
  layout: 'event',
  scrollToTop: false,
  async asyncData ({ $api, $config, store, route: { path, params: { slug }}, error }) {
    const {
      data: {
        items: event = [],
      } = {},
    } = await $api.events.getOneBySlug(slug, {
      domain: $config.preprEnvironmentDomain,
    })

    // Check publication if exists
    if (!event || !event.length) {
      error({
        context: `Couldn't create event.`,
        message: '(404) Evenement niet gevonden',
        severity: 'info',
        statusCode: 404,
      })
      return false
    }

    // Set breadcrumbs
    store.dispatch('breadcrumbs/set', {
      path,
      eoRoot: false,
      floating: true,
      width: 10,
      items: [
        {
          to: '/',
          label: 'BEAM',
        },
        {
          label: event[0].title,
        },
      ],
    })

    return event[0]
  },
  data () {
    return {
      isEventInFuture: null,
      isEventStartingToday: null,
      isEventIn30Min: null,
      isEventHappening: null,
      isEventInPast: null,
      readableDate: null,
      articles: [],
      callToAction: null,
      color: null,
      changedOn: null,
      description: [],
      endDate: null,
      environmentId: null,
      faq: [],
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      lineUp: [],
      livestream: null,
      location: null,
      logo: 'https://beam-eo.cdn.eo.nl/{format}/3xinyexgfp3z-eojd-logo-2023.png',
      program: [],
      publishOn: null,
      seo: null,
      slug: null,
      social: null,
      startDate: null,
      subtitle: null,
      terms: [],
      tickets: [],
      title: null,
      topic: null,
    }
  },
  async fetch () {
    const {
      data: {
        items: articles = [],
      } = {},
    } = await this.$api.articles.getAll({
      offset: this.articles.length,
      limit: 6,
      event: this.id,
    })

    this.articles.push(...articles)
  },
  watch: {
    startDate: {
      handler: function () {
        // Create a readable date every second, if needed
        this.setReadableDate()
        if (this.isEventIn30Min || this.isEventHappening) {
          setInterval(this.setReadableDate.bind(this), 1000)
        }
      },
      immediate: true,
    },
  },
  methods: {
    setReadableDate () {
      if (!this.startDate) return
      this.$moment.relativeTimeThreshold('ss', 0)

      // Get relative dates
      const now = this.$moment()
      const start = isNaN(this.startDate) ? this.$moment(this.startDate) : this.$moment.unix(this.startDate)
      const minutesToStart = start.diff(now, 'minutes', true)
      const minutesFromStart = now.diff(start, 'minutes', true)
      let end
      let minutesToEnd
      let minutesFromEnd
      if (this.endDate) {
        end = isNaN(this.endDate) ? this.$moment(this.endDate) : this.$moment.unix(this.endDate)
        minutesToEnd = end.diff(now, 'minutes', true)
        minutesFromEnd = now.diff(end, 'minutes', true)
      }

      // Set relative date booleans
      this.isEventStartingToday = start.isSame(now, 'day') && minutesToStart > 0
      this.isEventIn30Min = minutesToStart > 0 && minutesToStart < 30
      this.isEventInFuture = minutesToStart > 0
      if (this.endDate) {
        this.isEventHappening = minutesToStart < 0 && minutesToEnd > 0
        this.isEventInPast = minutesFromEnd > 0
      } else {
        this.isEventHappening = minutesToStart == 0
        this.isEventInPast = minutesFromStart > 0
      }
      
      // Generate human readable date
      this.readableDate =
        this.isEventIn30Min ? `Begint ${start.fromNow()}` :
        this.isEventHappening && minutesToStart > -5 ? 'Zojuist begonnen' :
        this.isEventHappening && this.endDate ? `Eindigt ${this.dateFormat.past(this.endDate)}` :
        this.isEventStartingToday ? `Vandaag om ${this.dateFormat.time(this.startDate)}` :
        this.isEventInFuture ? `${this.dateFormat.dayDateAndTime(this.startDate)}u` :
        this.isEventInPast && minutesToStart > -5 ? 'Zojuist plaatsgevonden' :
        this.isEventInPast && minutesFromEnd < 5 ? 'Zojuist geëindigd' :
        `Dit evenement is voorbij`
    },
  },
}
